import React, {FC} from 'react';

//MUI
import Box from '@mui/material/Box';
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';

//MUI-ICONS
import QrCode2Icon from '@mui/icons-material/QrCode2';
import {Initiative, UUID} from '@/domain/entities/Initiative';
import {useTranslation} from 'react-i18next';
import Button from '@mui/material/Button';

//TYPES
export type QrComponentProps = {
  initiative: Initiative,
  onQrGenerate: (uuid: UUID) => void
};

const containerSx: SxProps = {
  position: 'relative',
  mx: 0,
  mt: 3 ,
  pb: 2
};

const QrComponent: FC<QrComponentProps> = ({initiative, onQrGenerate}) => {

  const {t} = useTranslation();
  const {uuid, qr, name} = initiative;

  return <Box sx={containerSx}>
    {
      qr
        ? <img src={qr} alt={`QR: ${name}`} style={{height: '180px'}}/>
        : <Button variant='contained' color='secondary' startIcon={<QrCode2Icon />} sx={{color: 'white'}} onClick={() => onQrGenerate(uuid)}>{t('initiatives.generate_qr')}</Button>
    }
  </Box>;
};
export default QrComponent;