import React, {FC, ReactElement} from 'react';
import SquareIcon from '@mui/icons-material/Square';
import Carousel from 'react-material-ui-carousel';

//TYPES
export type CarouselGreenBonusProps = {
  children: Array<ReactElement> | Array<Array<ReactElement>>,
  dots?: boolean
  autoplay?: boolean,
  height?: string,
  indicatorContainerProps?: object
};

//UTILS
import '@/utils/reactCarousel.css';

const CarouselGreenBonus: FC<CarouselGreenBonusProps> = ({children, dots = true, autoplay = false, height=undefined, indicatorContainerProps=undefined}) => {

  return <Carousel
    autoPlay={autoplay}
    navButtonsAlwaysInvisible={true}
    animation="fade"
    duration={10}
    indicators={dots}
    IndicatorIcon={<SquareIcon sx={{fontSize: '14px', mx: 0.25}}/>}
    swipe={true}
    height={height}
    indicatorContainerProps={indicatorContainerProps}
  >
    {children}
  </Carousel>;
};
export default CarouselGreenBonus;