import React, {FC, useEffect, useMemo, useRef, useState} from 'react';

//GEOCOMPONENTS
import Map from '@geomatico/geocomponents/Map/Map';
import BaseMapPicker from '@geomatico/geocomponents/Map/BaseMapPicker';

//MUI
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';

//MUI-ICONS
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';

//GREEN BONUS
import PoisCollection from '@/components/admin/PoisCollection';
import InitiativesSummary from '@/components/admin/InitiativesSummary/InitiativesSummary';
import PlacesDetail from '@/components/admin/PlacesDetail/PlaceDetail';
import Footer from '@/components/admin/Footer';
import PopUpAvailability from '@/components/admin/PopUpAvailability';

//UTILS
import {INITIAL_VIEWPORT, MAPSTYLES} from '@/config';
import {AnyLayer, MapLayerMouseEvent, MapWheelEvent, Sources, Style} from 'mapbox-gl';
import {BaseInitiative, Initiative, STATUS, UUID} from '@/domain/entities/Initiative';
import bbox from '@turf/bbox';
import type {MapRef} from 'react-map-gl';
import {Popup} from 'react-map-gl';
import {Feature} from 'geojson';
import buildLayers from '@/domain/useCases/map/buildLayers';
import buildSources from '@/domain/useCases/map/buildSources';

import {getPoisData} from '@/utils/getPoisData';
import {asFeatureCollection} from '@/utils/asFeatureCollection';

import {Marker} from 'react-map-gl';

import {orange} from '@mui/material/colors';
import styled from '@mui/system/styled';
import '@/utils/popUp.css';
import PerformersCard from '@/components/PerformersCard';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {PerformerEmployee} from '@/domain/entities/PerformerEmployee';

export type MainContentProps = {
  initiatives?: Array<Initiative>,
  availableInitiatives?: Array<BaseInitiative>,
  mapStyle: Style | string,
  mapStyleId: string,
  currentPerformer: Array<PerformerEmployee> | undefined
  onStyleChange: (mapStyleId: string) => void,
  onContactClick: (uuid: UUID) => void,
  onQrGenerate: (uuid: UUID) => void,
};

type PopUpType = {
  lon: number,
  lat: number,
  initiative: BaseInitiative | undefined
}

const MainContent: FC<MainContentProps> = ({
  initiatives,
  availableInitiatives,
  mapStyle,
  mapStyleId,
  onStyleChange,
  onContactClick,
  currentPerformer,
  onQrGenerate
}) => {
  const {t} = useTranslation();
  const [viewport, setViewport] = useState(INITIAL_VIEWPORT);
  const [isScrollEnabled, setScrollEnabled] = useState(false);
  const mapRef = useRef<MapRef>(null);
  
  const [clickedMyInitiativeId, setClickedMyInitiativeId] = useState<number | string | null>(null);
  const [popUpContent, setPopUpContent] = useState<PopUpType>();

  //Redirecciona al resumen de la iniciativa
  useEffect(() => {
    if (clickedMyInitiativeId) {
      window.location.href = `#place-${clickedMyInitiativeId}`;
    } else {
      setClickedMyInitiativeId(null);
    }
  }, [clickedMyInitiativeId]);

  // Cuando el usuario hace scroll, se vuelve null el clickedMyInitiativeId
  useEffect(() => {
    const handleScroll = () => setClickedMyInitiativeId(null);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleWheel = (e: MapWheelEvent) => {
    if (e.originalEvent.ctrlKey) {
      setScrollEnabled(true);
      e.originalEvent.preventDefault();
    } else {
      setScrollEnabled(false);
    }
  };

  //Hace zoom al bounding box de las iniciativas del usuario y si no hay a las availableInitiatives
  useEffect(() => {
    if (initiatives && initiatives.length > 0) {
      zoomToFeatures(initiatives.flatMap(i => i.parcels_rsc.features));
    } else if (availableInitiatives && availableInitiatives.length > 0) {
      zoomToFeatures(availableInitiatives.flatMap(i => i.parcels_rsc.features));
    }
  }, [initiatives]);

  const zoomToFeatures = (features: Array<Feature>) => {
    const geojson = {
      type: 'FeatureCollection',
      features
    };
    const [minX, minY, maxX, maxY] = bbox(geojson);
    if (minX && minY && maxX && maxY && mapRef.current && 'fitBounds' in mapRef.current) {
      mapRef.current?.fitBounds([[minX, minY], [maxX, maxY]],
        {
          duration: 2000,
          padding: {
            left: 50,
            right: 50,
            top: 475,
            bottom: 150
          }
        }
      );
    }
  };
  
  //STATUS AVAILABLE INITIATIVES
  const filterInitiativeStatus = (status: STATUS) => availableInitiatives?.filter(i => i.status === status);
  const interested = availableInitiatives?.filter(initiative =>
    initiative.status === STATUS.INTERESTED);
  const available = availableInitiatives?.filter(initiative =>
    initiative.status === STATUS.AVAILABLE);
  const purchased = availableInitiatives?.filter(initiative => 
    initiative.status === STATUS.PURCHASED);
  
  const availableInitiativeIds = available && available.map(initiative => initiative.uuid);

  //POIS
  const interestedPois = useMemo(
    () => filterInitiativeStatus(STATUS.INTERESTED) && getPoisData(filterInitiativeStatus(STATUS.INTERESTED)), [availableInitiatives]);

  const availablePois = useMemo(
    () => filterInitiativeStatus(STATUS.AVAILABLE) && getPoisData(filterInitiativeStatus(STATUS.AVAILABLE)), [availableInitiatives]);

  const purchasedPois = useMemo(
    () => filterInitiativeStatus(STATUS.PURCHASED) && getPoisData(filterInitiativeStatus(STATUS.PURCHASED)), [availableInitiatives]);

  const justInitiativesPurchased = useMemo(
    () => initiatives && initiatives
      .filter(initiative => initiative.status !== 'available')
      .filter(initiative => initiative.status !== 'interested')
    , [initiatives]);
  
  const initiativePois = useMemo(
    () => justInitiativesPurchased && getPoisData(justInitiativesPurchased), [justInitiativesPurchased]);

  
  //SOURCES Y LAYERS
  const availableFeatures = useMemo(() => {
    return availableInitiatives && asFeatureCollection(availableInitiatives);
  }, [availableInitiatives]);

  const sources = useMemo<Sources | undefined>(
    () => buildSources(initiatives, interested, available, purchased),
    [availableInitiatives, initiatives]);

  const layers = useMemo<Array<AnyLayer>>(
    () => buildLayers(initiatives, interested, available, purchased),
    [availableFeatures, initiatives]
  );

  const handleMapClick = (e: MapLayerMouseEvent) => {

    if(!e.features?.length) {
      setPopUpContent(undefined);
      return;
    }

    if(e.features && e.features.length) {
      
      //En una disponible
      if(availableInitiativeIds && availableInitiativeIds.includes(e.features && e.features[0].properties?.initiativeId)) {
        setPopUpContent({
          lon: e.lngLat.lng,
          lat: e.lngLat.lat,
          initiative: availableInitiatives?.find(initiative => e.features && (initiative.uuid === e.features[0].properties?.initiativeId))
        });
      //En una mía
      } else {
        setClickedMyInitiativeId(e.features && e.features[0].properties?.initiativeId);
      }

    }
  };
  
  const CustomPopup = styled(Popup)({
    '& .mapboxgl-popup-content': {
      padding: 0
    }
  });
  
  return <>
    <Map
      ref={mapRef}
      mapStyle={mapStyle}
      viewport={viewport}
      onViewportChange={setViewport}
      scrollZoom={isScrollEnabled}
      onWheel={handleWheel}
      cooperativeGestures={true}
      sources={sources}
      layers={layers}
      interactiveLayerIds={['initiative-background', 'available-background']}
      onClick={handleMapClick}
    >
      <Typography variant='subtitle' sx={{boxShadow: 1, position: 'absolute', top: 170, left: 12, py: 1, px: 4, bgcolor: 'common.white', borderRadius: 1, opacity: 0.9, border: '1px solid lightgrey', }}>{t('market').toUpperCase()}</Typography>
      {
        availablePois && <PoisCollection poisData={availablePois} 
          onPoiClick={(availableId) => {
            if(availableId === popUpContent?.initiative?.uuid) return;

            const poiData = availableInitiatives && availablePois.find(feature => feature.initiativeId === availableId);

            if(poiData) {
              setPopUpContent({
                lon: poiData.centroids[0].lon,
                lat: poiData.centroids[0].lat,
                initiative: availableInitiatives.find(availableInitiative => availableInitiative.uuid === availableId)
              });
            }
          }}
        >
          <Fab size='small' color='primary'>
            <ThumbUpOffAltIcon sx={{color: 'white'}}/>
          </Fab>
        </PoisCollection>
      }

      {
        purchasedPois && <PoisCollection poisData={purchasedPois}>
          <Fab size='small' sx={{bgcolor: theme => theme.palette.grey[600], '&:hover': {bgcolor: theme => theme.palette.grey[700]}}}>
            <LockOutlinedIcon sx={{color: 'white'}}/>
          </Fab>
        </PoisCollection>
      }


      {
        interestedPois && <PoisCollection poisData={interestedPois}>
          <Fab size='small' sx={{bgcolor: orange[500], '&:hover': {bgcolor: orange[700]}}}>
            <FlagOutlinedIcon sx={{color: 'white'}}/>
          </Fab>
        </PoisCollection>
      }

      {
        initiativePois && initiativePois?.map(({centroids, initiativeId, logoPerformers}) => (
          centroids.map(({lat, lon}, index) => 
            <Marker key={`id${initiativeId}-${index}`} longitude={lon} latitude={lat}
              onClick={(e) => {
                e.originalEvent.stopPropagation();
                initiativeId && setClickedMyInitiativeId(initiativeId);
              }}>
              {
                logoPerformers && logoPerformers.length > 1 ?
                  <PerformersCard logoPerformers={logoPerformers} height='35px'/>
                  : logoPerformers ? <Fab size='small' sx={{bgcolor: 'white'}}>
                    <img key={index} src={logoPerformers[0]} alt=''
                      style={{maxWidth: '38px', maxHeight: '38px', mixBlendMode: 'multiply'}}/>
                  </Fab> : <AccountCircleIcon color='secondary' sx={{fontSize: '30px'}}/>
              }
            </Marker>
          )
        ))
      }
      {
        !!popUpContent && popUpContent?.initiative &&
              <CustomPopup
                longitude={popUpContent.lon}
                latitude={popUpContent.lat}
                offset={40}
              >
                <PopUpAvailability initiative={popUpContent && popUpContent.initiative} 
                  onContactClick={(id) => {
                    onContactClick(id);
                    setPopUpContent(undefined);
                  }                    
                  }/>
              </CustomPopup>
      }
    </Map>
    <BaseMapPicker styles={MAPSTYLES} selectedStyleId={mapStyleId} onStyleChange={onStyleChange}/>
    <Box m={4}>
      {initiatives && initiatives.length > 0 && <InitiativesSummary initiatives={initiatives} currentPerformer={currentPerformer}/>}
      <Box mt={4}/>
      {
        initiatives && initiatives.length > 0 && initiatives.map(initiative => 
          <PlacesDetail key={initiative.uuid} initiative={initiative} onQrGenerate={onQrGenerate}/>)
      }
    </Box>
    <Footer/>
  </>;
};

export default MainContent;