import React, {FC, ReactElement} from 'react';

import {Marker} from 'react-map-gl';
import {UUID} from '@/domain/entities/Initiative';

type Poi = {
  centroids: Array<{ lat: number, lon: number }>
  initiativeId: UUID
  logoPerformers: Array<string> | undefined
}

export type AvailablePoisProps = {
  poisData: Array<Poi>,
  children: ReactElement,
  onPoiClick?: (availableId: number | string) => void
};

const PoisCollection: FC<AvailablePoisProps> = ({poisData, children, onPoiClick}) => {
  return <>
    {
      poisData?.map(({centroids, initiativeId}) => (
        centroids.map(({lat, lon}, index) => <Marker key={`id${initiativeId}-${index}`} longitude={lon} latitude={lat}
          onClick={(e) => {
            e.originalEvent.stopPropagation();
            initiativeId && onPoiClick && onPoiClick(initiativeId);
          }}>
          {children}
        </Marker>
        )
      ))
    }
  </>;
};

export default PoisCollection;