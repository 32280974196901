import React, {FC} from 'react';

//MUI
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import EmailIcon from '@mui/icons-material/Email';

//RSC
import SustainabilityList from '@/components/admin/InitiativesSummary/SustainabilityActionList';

//UTILS
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import {useTranslation} from 'react-i18next';
import {grey} from '@mui/material/colors';
import {BaseInitiative, UUID} from '@/domain/entities/Initiative';

const containerSx: SxProps = {
  paddingTop: 1,
  width: 440,
  boxShadow: 2
};

const actionsSx: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  bgcolor: 'secondary.light',
  py: 1.5,
  cursor: 'pointer'
};

export type PopUpAvailabilityProps = {
  initiative: BaseInitiative
  onContactClick: (uuid: UUID) => void
};

const PopUpAvailability: FC<PopUpAvailabilityProps> = ({initiative, onContactClick}) => {

  const {t} = useTranslation();

  const {uuid, place, area, sustainability_actions} = initiative;

  return <Card variant='outlined' sx={containerSx}>
    <CardContent sx={{px: 4}}>
      <Typography sx={{pb: 2, fontWeight: 'bold'}}>{t('field').toUpperCase()} &quot;{place.toUpperCase()}&quot;</Typography>
      <Box sx={{display: 'flex', flexDirection: 'row', pb: 2}}>
        <Typography sx={{color: grey[400]}}>{t('initiatives.area').toUpperCase()} </Typography>
        <Typography sx={{ml: 1}}>{area} ha</Typography>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column', pb: 2}}>
        <Typography sx={{color: grey[400]}}>{t('initiatives.achievable_sustainability_actions').toUpperCase()}</Typography>
        <SustainabilityList sustainabilityActions={sustainability_actions.map(item => item)}/>
      </Box>
    </CardContent>
    <CardActions sx={actionsSx} onClick={() => onContactClick(uuid)}>
      <EmailIcon color='primary'/>
      <Typography color='primary' sx={{ml: 2}}>{t('contact').toUpperCase()}</Typography>
    </CardActions>
  </Card>;
};
export default PopUpAvailability;