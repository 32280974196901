import {FeatureCollection, MultiPolygon} from 'geojson';
import {Bbox} from '@/domain/commonTypes';

import {Specie} from '@/domain/entities/Specie';
import {Performer} from '@/domain/entities/Performer';
import {Collaborator} from '@/domain/entities/Collaborator';
import {Owner} from '@/domain/entities/Owner';
import {SkyState} from '@/domain/entities/Meteo';

export type UUID = string;

export enum STATUS {
  AVAILABLE = 'available',
  PURCHASED = 'purchased',
  INTERESTED = 'interested',
  IN_PROCESS = 'in_process'
}

export enum CERTIFICATION_TYPES {
  FSC = 'FSC',
  PEFC = 'PEFC',
  FSC_SE = 'FSC-SE',
  FSC_MONTRES = 'FSC_MONTRES'
}

export interface SustainabilityActionType {
  id: number,
  description: string,
  name: string
}

export interface SustainabilityActionWithIconType {
  id: number
  icon: string,
  description: string,
  name: string
}

export type EnvironmentalImpact = {
  name: string
}

export type Meteo = {
  temperature: number,
  sky_state: SkyState
}

export type Resource = {
  id: number,
  title: string,
  text: string,
  image: string
}


export type Execution = {
  id: number,
  executed_year: number,
  comments: string,
  image: string
}

export type Intervention = {
  id: number,
  title: string,
  text: string,
  planned_year: number,
  image: string,
  executions: Array<Execution>
}

export type Plan = {
  id: number,
  title: string,
  description: string,
  year: number,
  interventions: Array<Intervention>
}

export type Testimony = {
  id: number,
  title: string,
  subtitle: string,
  text: string,
  image: string
}

export interface BaseInitiative {
  uuid: UUID,
  title: string,
  subtitle: string,
  name: string,
  description: string,
  place: string,
  objective: string,
  investment: string,
  year: number,
  owners: Array<Owner>,
  area: number,
  sustainability_actions: Array<SustainabilityActionType> | [],
  bbox: Bbox,
  meteo: Meteo,
  parcels_rsc: FeatureCollection<MultiPolygon>,
  status: STATUS,
}

export interface Initiative extends BaseInitiative {
  action_plan: Plan,
  performers: Array<Performer>
  certification_type: Array<CERTIFICATION_TYPES> | null,
  species: Array<Specie>,
  collaborators: Array<Collaborator>,
  document: string,
  environmental_impacts: Array<EnvironmentalImpact>,
  resources: Array<Resource>,
  testimonials: Array<Testimony>,
  commitment: {
    id: number,
    title: string,
    description: string,
    video?: string
  },
  qr: string
}