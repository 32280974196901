import {Sources} from 'mapbox-gl';
import {BaseInitiative, Initiative} from '@/domain/entities/Initiative';


const buildSources = (
  initiatives: Array<Initiative> | undefined,
  interested: Array<BaseInitiative> | undefined,
  available: Array<BaseInitiative> | undefined,
  purchased: Array<BaseInitiative> | undefined,
    
) => {
  const sources: Sources = {};
  if (interested) {
    sources['interested'] = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: interested.flatMap(interestedInitiative =>
          interestedInitiative.parcels_rsc.features.map(feature => ({
            ...feature,
            properties: {
              ...feature.properties,
              initiativeId: interestedInitiative.uuid
            }
          }))
        )
      }
    };
  }
  if (available) {
    sources['available'] = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: available.flatMap(availableInitiative =>
          availableInitiative.parcels_rsc.features.map(feature => ({
            ...feature,
            properties: {
              ...feature.properties,
              initiativeId: availableInitiative.uuid
            }
          }))
        )
      }
    };
  }
  if (purchased) {
    sources['purchased'] = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: purchased.flatMap(purchasedInitiative =>
          purchasedInitiative.parcels_rsc.features.map(feature => ({
            ...feature,
            properties: {
              ...feature.properties,
              initiativeId: purchasedInitiative.uuid
            }
          }))
        )
      }
    };
  }
  if (initiatives) {
    sources['initiatives'] = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: initiatives.flatMap(initiative =>
          initiative.parcels_rsc.features.map(intervention_zone => ({
            ...intervention_zone,
            properties: {
              ...intervention_zone.properties,
              initiativeId: initiative.uuid
            }
          }))
        )
      }
    };
  }
  return sources;
};

export default buildSources;