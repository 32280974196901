import React, {CSSProperties, FC} from 'react';
import {Testimony} from '@/domain/entities/Initiative';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {useTranslation} from 'react-i18next';
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import {useMediaQuery} from '@mui/material';
import CarouselGreenBonus from '@/components/CarouselGreenBonus';

const headerSx: SxProps = {
  py: 3,
  gridArea: 'header',
  bgcolor: 'primary.main',
  outline: '1px solid primary.main'
};

const emptySx: SxProps = {
  py: 3,
  gridArea: 'empty',
  bgcolor: 'primary.main',
  outline: '1px solid primary.main'
};

const backgroundImage: SxProps = {
  py: 3,
  gridArea: 'image',
  bgcolor: 'primary.dark'
};

const empty3Sx: SxProps = {
  py: 3,
  gridArea: 'empty3',
  bgcolor: 'primary.dark',
  outline: '1px solid #81a252'
};

const titleSx: SxProps = {
  pr: 1,
  gridArea: 'title',
  bgcolor: 'primary.main'
};


//TYPES
export type TestimonyItemProps = {
  testimonials: Array<Testimony>
};

const TestimonialsItem: FC<TestimonyItemProps> = ({testimonials}) => {

  if (!testimonials.length) {
    return <></>;
  }

  const {t} = useTranslation();
  const widescreen = useMediaQuery('(min-width: 900px)', {noSsr: true});

  const imageStyle: CSSProperties = {
    filter: 'grayscale(100%)',
    maxWidth: widescreen ? '200px' : '100%',
    position: 'absolute',
    right: 10,
    bottom: 0,
    margin: 0,
    padding: 0,
    float: 'right'
  };
  
  const constainerSx: SxProps = {
    width: '100vw',
    display: 'grid',
    bgcolor: 'primary.dark',

    gridTemplateAreas: widescreen ? `
    'empty empty title title' 'image image text empty3'
    `:`
    'empty title' 'image text'
    `,
    gridTemplateColumns: widescreen ? '1fr 1fr minmax(0, 2fr) 1fr' : '2fr minmax(0, 4fr)',
    gridTemplateRows: '1fr min-content auto'
  };


  const textSx: SxProps = {
    py: 2,
    pr: 1,
    bgcolor: 'primary.dark',
    minHeight: '200px',
    fontStyle: 'italic',
    color: 'secondary.light',
    gridArea: 'text'
  };

  return <Box>
    <Typography variant="title" textAlign="center" color="secondary.main" sx={headerSx}>{t('testimony').toUpperCase()}</Typography>
    <CarouselGreenBonus autoplay={true} dots={true} indicatorContainerProps={{style: {position: 'absolute', zIndex: 1000, marginTop: -25}}}>
      {
        testimonials.map(({id, title, subtitle, text, image}) =>
          <Box key={id} sx={constainerSx}>
            <Box display="flex" flexDirection="column" sx={titleSx}>
              <Typography variant="subtitle" color="secondary.main">{title}</Typography>
              <Typography color="secondary.main">{subtitle}</Typography>
            </Box>
            <Box display="flex" flexDirection="column" sx={{justifyContent: 'flex-end', gridArea: 'image', position: 'relative', width: '100%'}}>
              <img src={image} alt={title} style={imageStyle}/>
            </Box>
            <Typography color="primary.grey" sx={textSx}>{text}</Typography>
    
            <Box sx={emptySx}/>
            <Box sx={backgroundImage}/>
            {widescreen && <Box sx={empty3Sx}/>}
          </Box>
        )
      }
    </CarouselGreenBonus> 
  </Box>;
};
export default TestimonialsItem;
