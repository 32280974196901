import {Token} from '@/domain/services/Auth';
import ApiGreenBonus from '@/domain/services/ApiGreenBonus';
import {GET, GET_PAGINATED, POST} from '@/serviceImpl/HttpFetch';
import {Performer} from '@/domain/entities/Performer';
import {PerformerEmployee} from '@/domain/entities/PerformerEmployee';
import {BaseInitiative, Initiative, UUID} from '@/domain/entities/Initiative';

const API_URL = process.env.BASE_URL;

const availableInitiatives = async (token: Token): Promise<Array<BaseInitiative>> =>
  GET(`${API_URL}/greenbonus/availableinitiatives/`, token);

const initiative = async (uuid: UUID): Promise<Initiative> =>
  GET(`${API_URL}/greenbonus/initiatives/${uuid}/`);
const initiatives = async (token: Token): Promise<Initiative[]> =>
  GET_PAGINATED(`${API_URL}/greenbonus/initiatives/`, token, 20);

const interestedInInitiative = async (id: UUID, body=undefined, token: Token): Promise<void> =>
  POST(`${API_URL}/greenbonus/availableinitiatives/${id}/interested_in/`, body, token);

const performers = async (token: Token): Promise<Performer[]> =>
  GET(`${API_URL}/greenbonus/performer/`, token);
const performer = async (id: number, token: Token): Promise<Performer> =>
  GET(`${API_URL}/greenbonus/performer/${id}`, token);

const performerEmployees = async (token: Token): Promise<Array<PerformerEmployee>> =>
  GET(`${API_URL}/greenbonus/performeremployees/`, token);
const performerEmployee = async (id: number, token: Token): Promise<PerformerEmployee> =>
  GET(`${API_URL}/greenbonus/performeremployees/${id}`, token);

const generateQr = async (uuid: UUID, token: Token): Promise<void> =>
  POST(`${API_URL}/greenbonus/initiatives/${uuid}/qr/`, undefined, token);


const GreenBonusImpl: ApiGreenBonus = {
  availableInitiatives,
  initiative,
  initiatives,
  interestedInInitiative,
  performers,
  performer,
  performerEmployees,
  performerEmployee,
  generateQr
};

export default GreenBonusImpl;